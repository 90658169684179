import hillo from 'hillo'

export async function getBonEnding () {
  return await hillo.get('Printer.php?op=getCheckoutBonEnding')
}

export async function postBonEnding (item) {
  return await hillo.post('Printer.php?op=setCheckoutBonEnding', item)
}

export async function getBonSetting () {
  return await hillo.get('Printer.php?op=getZbonPrintSettings')
}

export async function postBonSetting (item) {
  return await hillo.post('Printer.php?op=setZbonPrintSettings', item)
}
